import React from 'react';
import { Helmet } from 'react-helmet';

import { graphql } from 'gatsby';
import PageWrapper from '../components/PageWrapper';
import HeaderButton from '../components/HeaderButton';
import BlogPost from '../components/BlogPost';

const Blog = ({ data }) => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-right',
          headerButton: <HeaderButton />,
          footerStyle: 'style3',
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>UStrive | Blog</title>
          <link rel="canonical" href="https://ustrive.com/blog" />
        </Helmet>
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">UStrive Blog</h2>
                  <p className="font-size-7 mb-7">
                    We provide insights on the college &amp; career journey, as
                    well as highlighting the latest developments on the
                    platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {data.allContentfulBlogPost.edges.map((post) => (
                <BlogPost post={post} />
              ))}
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export const pageQuery = graphql`
  query blogPosts {
    allContentfulBlogPost {
      edges {
        node {
          id
          published(fromNow: true)
          author
          title
          slug
          image {
            fixed(width: 1600) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`;

export default Blog;
