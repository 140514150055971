import React from 'react';
import { Link } from 'gatsby';

const BlogPost = ({ post }) => {
  return (
    <div className="col-lg-6 col-md-10">
      <Link key={post.node.id} to={`/post/${post.node.slug}`}>
        <div className="bg-white d-xs-flex align-items-center px-9 py-10 mb-9 shadow-2 gr-hover-1">
          {post.node.image && (
            <div className="mr-10">
              <img
                className="square-116"
                src={post.node.image.fixed.src}
                alt=""
              />
            </div>
          )}

          <div className="mt-8 mt-xs-0">
            <h4 className="font-size-8 font-weight-medium text-dark-cloud mb-5 line-height-34">
              {post.node.title}
            </h4>
            <div className="d-flex align-items-center flex-wrap">
              <span className="text-bali-gray font-size-3 pr-9">
                <i className="fa fa-clock mr-2"></i>
                {post.node.published}
              </span>
              <span className="text-bali-gray font-size-3 pr-9">
                <i className="fa fa-user mr-2"></i>
                {post.node.author}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BlogPost;
